import React from "react";
import type { FC } from "react";
import GlobalStyles from "@mui/material/GlobalStyles";
import { useTheme } from "@mui/material";

import { Theme } from "../../theme";

const GlobalStyling: FC = () => {
  const appTheme: Theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
        },
        html: {
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
          width: "100%",
          backgroundColor: appTheme.palette.background.default,
        },
        "#root": {
          height: "100%",
          width: "100%",
        },
        a: { textDecoration: "none" },
        img: { display: "block", maxWidth: "100%" },

        // Lazy Load Img
        ".blur-up": {
          WebkitFilter: "blur(5px)",
          filter: "blur(5px)",
          transition: "filter 400ms, -webkit-filter 400ms",
        },
        ".blur-up.lazyloaded ": {
          WebkitFilter: "blur(0)",
          filter: "blur(0)",
        },
        "input:-webkit-autofill": {
          backgroundColor: "none",
        },
      }}
    />
  );
};

export default GlobalStyling;
