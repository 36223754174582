import { combineReducers } from "redux";
import { hireUsReducer } from "./HireUsReducer";

const rootReducer = combineReducers({
  hireUsState: hireUsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
