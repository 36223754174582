import {
  CLEAR_HIRE_US,
  HIRE_US_UPDATE_FAILURE,
  HIRE_US_UPDATE_SUCCESS,
  LOADING,
} from "../actions/HireUs";

import { IHireUsResponseAction } from "../contracts/HireUs";

export const hireUsReducer = (state = {}, action: IHireUsResponseAction) => {
  switch (action.type) {
    case HIRE_US_UPDATE_FAILURE:
      return { ...action.payload };

    case HIRE_US_UPDATE_SUCCESS:
      return { ...action.payload };

    case LOADING:
      return { ...action.payload };

    case CLEAR_HIRE_US:
      return {};

    default:
      return state;
  }
};
