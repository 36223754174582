import { put, call, takeLatest } from "redux-saga/effects";

import {
  HIRE_US_UPDATE,
  HIRE_US_UPDATE_FAILURE,
  HIRE_US_UPDATE_SUCCESS,
  LOADING,
} from "../actions/HireUs";
import { IHireUsPayloadAction, IHireUsResponse } from "../contracts/HireUs";

import { updateHireUs } from "../apiclient/HireUs";

export function* wathHireUs() {
  yield takeLatest([HIRE_US_UPDATE], handleHireUs);
}

export function* handleHireUs(payload: IHireUsPayloadAction) {
  switch (payload.type) {
    case HIRE_US_UPDATE:
      // Notify store we are loading
      yield put({
        type: LOADING,
        payload: { loading: true },
      });

      const hireusUpdateRes: IHireUsResponse = yield call(
        updateHireUs,
        payload.payload
      );

      yield put({
        type:
          hireusUpdateRes && hireusUpdateRes.error
            ? HIRE_US_UPDATE_FAILURE
            : HIRE_US_UPDATE_SUCCESS,
        payload: hireusUpdateRes,
      });
      break;

    default:
      break;
  }
}
