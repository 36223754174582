import React from "react";
import type { FC } from "react";

// Material
import {
  Box,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  styled,
  alpha,
  useTheme,
  Divider,
} from "@mui/material";
import { Instagram, Twitter } from "@mui/icons-material";

// Theme
import { Theme } from "../../../theme";

// Styles
const RowStyle = styled("div")(() => {
  const appTheme: Theme = useTheme();

  return {
    position: "relative",
    backgroundColor: appTheme.palette.background.dark,
    paddingTop: appTheme.spacing(6),
    paddingBottom: appTheme.spacing(1),
    "& dt": {
      marginTop: appTheme.spacing(2),
    },
    backgroundImage:
      appTheme.palette.mode === "light"
        ? `linear-gradient(180deg, ${alpha(
            appTheme.palette.grey[400],
            0
          )} 0%, ${appTheme.palette.grey[400]} 100%)`
        : "none",
  };
});

const ListItemTextStyled = styled(ListItemText)(() => {
  const appTheme: Theme = useTheme();

  return {
    "& *": {
      color: appTheme.palette.text.secondary,
      paddingLeft: 10,
    },
  };
});

const AddressStyled = styled("address")(() => {
  const appTheme: Theme = useTheme();

  return {
    color: appTheme.palette.text.secondary,
  };
});

const SocialLinksStyle = styled("div")(() => {
  const appTheme: Theme = useTheme();

  return {
    paddingTop: "10px",
    textDecoration: "none",
    "& *": {
      color: appTheme.palette.text.secondary,
    },
    "& a": {
      textDecoration: "none",
      paddingRight: "10px",
    },
  };
});

const BoxFooterMarkStyled = styled(Box)(() => {
  const appTheme: Theme = useTheme();
  return {
    textAlign: "left",
    textDecoration: "none",
    "& *": {
      color: appTheme.palette.text.secondary,
    },
    "& a": {
      textDecoration: "none",
      paddingRight: "10px",
    },
  };
});

const FooterIconStyle = styled("img")(() => {
  return {
    width: "24px",
  };
});

// interfaces
interface IFooterProps {
  className?: string;
}

// Main Component
const Footer: FC<IFooterProps> = ({ ...rest }) => {
  return (
    <RowStyle {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3} component="dl">
          <Grid item xs={12} md={4}>
            <Box mt={6}>
              <dt>
                <Typography variant="h4" color="textPrimary">
                  Who we are
                </Typography>
              </dt>
              <dd>
                <Typography variant="body1" color="textSecondary">
                  We are technology company bound to limitless imagination.
                </Typography>
              </dd>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mt={6}>
              <dt>
                <Typography variant="h4" color="textPrimary">
                  What we offer
                </Typography>
              </dt>
              <dd>
                <List
                  disablePadding
                  sx={{
                    paddingTop: "20px",
                    "& li": {
                      padding: "2px 0px 2px 0px",
                    },
                  }}
                >
                  <ListItem disableGutters>
                    <FooterIconStyle
                      alt="mobile"
                      src="/static/home/icons/web.png"
                      width="100%"
                    />
                    <ListItemTextStyled primary="Web development" />
                  </ListItem>
                  <ListItem disableGutters>
                    <FooterIconStyle
                      alt="mobile"
                      src="/static/home/icons/mobile.png"
                      width="100%"
                    />
                    <ListItemTextStyled primary="Mobile apps" />
                  </ListItem>
                  <ListItem disableGutters>
                    <FooterIconStyle
                      alt="mobile"
                      src="/static/home/icons/integration.png"
                      width="100%"
                    />
                    <ListItemTextStyled primary="Systems Integration" />
                  </ListItem>
                  <ListItem disableGutters>
                    <FooterIconStyle
                      alt="mobile"
                      src="/static/home/icons/quality.png"
                      width="100%"
                    />
                    <ListItemTextStyled primary="Software Quality Assurance" />
                  </ListItem>
                </List>
              </dd>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mt={6}>
              <dt>
                <Typography variant="h6" color="textPrimary">
                  Phone
                </Typography>
              </dt>
              <dd>
                <AddressStyled>(+254)717-156850</AddressStyled>
              </dd>
              <dt>
                <Typography variant="h6" color="textPrimary">
                  Email Address
                </Typography>
              </dt>
              <dd>
                <AddressStyled>info@upfik.com</AddressStyled>
              </dd>
              <dt>
                <Typography variant="h6" color="textPrimary">
                  Social Links
                </Typography>
              </dt>
              <dd>
                <SocialLinksStyle>
                  <a
                    href="https://www.instagram.com/upfik"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Instagram />
                  </a>
                  <a
                    href="https://twitter.com/upfik"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Twitter />
                  </a>
                </SocialLinksStyle>
              </dd>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <BoxFooterMarkStyled>
              <Typography variant="caption">
                <Divider sx={{ marginBottom: 2 }} />
                <span dangerouslySetInnerHTML={{ __html: "&copy;" }} />
                <span>{new Date().getFullYear()} </span>
                <span>by Upfik </span>
                <br />
                <span> Icons provided by </span>
                <a href="https://icons8.com" target="_blank" rel="noreferrer">
                  icons8.com
                </a>
              </Typography>
            </BoxFooterMarkStyled>
          </Grid>
        </Grid>
      </Container>
    </RowStyle>
  );
};

export default Footer;
