import React from "react";
import type { FC } from "react";

// Material
import { Typography, styled, useTheme } from "@mui/material";

// Themes constant
import { THEMES } from "../../constants";

// Theme
import { Theme } from "../../theme";

// Styles
const TypographyStyle = styled(Typography)(() => ({}));

const ImageStyle = styled("img")(() => ({}));

// Interfaces
interface ILogoProps {
  [key: string]: any;
}

// Main component
const Logo: FC<ILogoProps> = (props) => {
  const appTheme: Theme = useTheme();

  const invertColor =
    !props.isOffeset && appTheme.name === THEMES.LIGHT && props.isHome;

  const logo =
    props && props.name ? (
      <TypographyStyle variant="h2" color={invertColor ? "#fff" : "primary"}>
        {props.name}
      </TypographyStyle>
    ) : (
      <ImageStyle alt="Logo" src="/static/logo.png" height="35" {...props} />
    );

  return <>{logo}</>;
};

export default Logo;
