const path = (link: string, subLink: string) => link + "/" + subLink;

const ROOT_MAIN = "";
const ROOT_ERROR = "/error";

export const PATHS = {
  // MAIN
  HOME: path(ROOT_MAIN, ""),
  ABOUT_US: path(ROOT_MAIN, "about-us"),
  HIRE_US: path(ROOT_MAIN, "hire-us"),

  // Errors
  ERROR_404: path(ROOT_ERROR, "404"),
  ERROR_500: path(ROOT_ERROR, "500"),
};
