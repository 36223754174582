import { IHireUsPayload, IHireUsPayloadAction } from "../contracts/HireUs";

export const HIRE_US_UPDATE = "HIRE_US_UPDATE";
export const HIRE_US_UPDATE_SUCCESS = "HIRE_US_UPDATE_SUCCESS";
export const HIRE_US_UPDATE_FAILURE = "HIRE_US_UPDATE_FAILURE";
export const LOADING = "LOADING";
export const CLEAR_HIRE_US = "CLEAR_HIRE_US";

export const hireUsUpdate = (payload: IHireUsPayload): IHireUsPayloadAction => {
  return {
    type: HIRE_US_UPDATE,
    payload,
  };
};

export const clearHireUs = () => ({ type: CLEAR_HIRE_US });
