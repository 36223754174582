import React from "react";
import type { FC } from "react";
import { BrowserRouter } from "react-router-dom";

//  Material
import { ThemeProvider } from "@mui/material/styles";

// Custom Components
import GlobalStyles from "./components/GlobalStyles";
import ScrollReset from "./components/ScrollReset";
import useSettings from "./hooks/useSettings";
import { createAppTheme } from "./theme";
import routes, { renderRoutes } from "./routes";

// App component
const App: FC = () => {
  const { settings } = useSettings();

  const theme = createAppTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <GlobalStyles />
        <ScrollReset />
        {renderRoutes(routes)}
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
